import React from "react";
import Header from "./assets/Header";
import Product from "./assets/Product";
import About from "./assets/About";
import Footer from "./assets/Footer"

export default function App() {
  return (
    <div className="animate__animated animate__fadeIn">
     <a href="https://wa.me/+919341238495"><button 
  className="rounded-3xl p-1.5 m-auto mb-5 flex items-center bg-gray-600 text-white fixed bottom-4 right-4 shadow-lg">
  <img  className="mr-1 rounded-full h-8" 
    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_nblYiIx928JptVZ2LY28rYzMsVFz8NkIvJ5tPgH6N8lAUcDvznyTiC2t&s=10" 
    alt="Contact Us"  />
  <p className="text-xs mr-1 product">Contact Us</p>
</button></a>
    <Header />
    <Product />
    <About />
    <Footer />
    </div>
  )
}