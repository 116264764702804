import React, { useState } from 'react';
import { SwipeableDrawer as Drawer } from '@mui/material';
import First from "./images/free.jpg";
import Second from "./images/second.jpg";
import Third from "./images/third.jpg";
import Fourth from "./images/fourth.jpg";
import product from "./style/popular-products/product_01.jpg";
import product_02 from "./style/popular-products/product_02.jpg";
import product_04 from "./style/popular-products/product_04.jpg";
import product_05 from "./style/popular-products/product_05.jpg";
import product_06 from "./style/popular-products/product_06.jpg";
import product_08 from "./style/popular-products/product_08.jpg";
import product_10 from "./style/popular-products/product_10.jpg";
import product_11 from "./style/popular-products/product_11.jpg";

function FreeProduct() {
  return(
  <div><span className="flex justify-center items-center"><img src={product_05} alt="product" height="100px" width="100px" className="rounded m-1" /><img  src={product_06} alt="product" height="100px" width="100px" className="rounded m-1" /><img  src={product} alt="new-arrivals images" width="100px" height="100px" className="rounded m-1" /></span><br /><b className="mt-2 bold">Special Offer :</b><br/><p className="paragraph">Purchase any other panel and receive the above-mentioned products absolutely free as part of this exclusive offer.</p></div>
   )
}

function Pro1() {
  return (
    <div>
      <span className="flex justify-center items-center">
        <img  src={product_02} alt="product" height="75px" width="75px" className="rounded m-1" />
        <img  src={product_08} alt="product" height="75px" width="75px" className="rounded m-1" />
        <img  src={product_10} alt="new-arrivals images" height="75px" width="75px" className="rounded m-1" />
        <img  src={product_11} alt="new-arrivals images" height="75px" width="75px" className="rounded m-1" />
      </span><br/>
      <b className="mt-2 bold">Product Description :</b><br/>
      <p className="paragraph">Newton Box (kerala box) (240x210x105mm) (9 1/2" X 8 1/4" X 4 1/4")</p>
    </div>
  );
}
function Pro2() {
  return (
    <div>
      <span className="flex justify-center items-center">
        <img  src={product_04} alt="product" height="75px" width="75px" className="rounded m-1" />
        <img  src={product_08} alt="product" height="75px" width="75px" className="rounded m-1" />
        <img  src={product_10} alt="new-arrivals images" height="75px" width="75px" className="rounded m-1" />
        <img  src={product_11} alt="new-arrivals images" height="75px" width="75px" className="rounded m-1" />
      </span><br/>
      <b className="mt-2 bold">Product Description :</b><br/>
      <p className="paragraph">Isi 'A' Type Frames 260 x 230 x 84 mm (10"x 9" x 31/4).</p>
    </div>
  ); 
}
function Pro3() {
  return (
    <div>
      <span className="flex justify-center items-center">
        <img  src={product_02} alt="product" height="75px" width="75px" className="rounded m-1" />
        <img  src={product_08} alt="product" height="75px" width="75px" className="rounded m-1" />
        <img  src={product_10} alt="new-arrivals images" height="75px" width="75px" className="rounded m-1" />
        <img  src={product_11} alt="new-arrivals images" height="75px" width="75px" className="rounded m-1" />
      </span><br/>
      <b className="bold">Product Description :</b><br/>
      <p className="paragraph">Isi 'B' Type Frames 330 x 295 x 104 mm (13" X 11 1/2" X 4").</p>
    </div>
  );
}

export default function Product() {
  const [isOpen, setIsOpen] = useState(false);
  const [mname, setMname] = useState("");
  const [html, setHtml] = useState("");
  
  const openModal = (product) => {
    if(product === "product_01"){
      setMname("Wax  de-capping roller");
      setHtml(<FreeProduct />);
    } else if(product === "product_02"){
      setMname("Plastic Super Frame");
      setHtml(<Pro1 />);
    } else if(product === "product_03"){
      setMname("Plastic Super Frame");
      setHtml(<Pro2 />);
    } else if(product === "product_04"){
      setMname("Plastic Super Frame");
      setHtml(<Pro3 />);
    }
      setIsOpen(true);
  };
  
  return (
    <>
     <Drawer
        anchor="bottom"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      > 
        <div className="p-10">
        <h1 className="product text-xl text-center mb-4">{mname}</h1>
           {html}
           <br />
          <span>
          <b className="bold"> Contact Details : </b> <br />
          <p className="paragraph">Address: Malgudi Bee Farms #442, 94TH Cross
          Kumarswamy Layout 1st State Bengaluru <br />
          Mobile Number: 9341238495 <br />
          E-mail: malgudi.beefarm@gmail.com</p>
          </span>
          <div className="mt-2">
          <button onClick={() => setIsOpen(false)} className="bg-none border product p-1.5 border-solid rounded-lg mt-3 mb-2 m-auto w-full border border-black" style={{ color: "#0891f3"}}>Close</button> 
          </div>
          <a href="https://wa.me/+919341238495"><button className="bg-none border product p-1.5 border-solid rounded-lg mb-5 m-auto w-full border border-black flex items-center bg-gray-600 text-white m-auto justify-center"><img  className="mr-1 rounded-full h-8" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_nblYiIx928JptVZ2LY28rYzMsVFz8NkIvJ5tPgH6N8lAUcDvznyTiC2t&s=10" alt="Contact Us" />
  <p className="text-xs product">Contact Us</p></button></a></div>
      </Drawer>
   <div className="flex items-center my-4 mt-8 relative m-5">
   <span className="absolute left-0 text-gray-600 bg-white text-sm font-bold hrfont px-2">WHAT WE OFFER</span>
   <hr className="flex-grow border-gray-600 border-t border-dashed border-black ml-4" />
   </div>
   <div className="flex">
   <div className="border h-44 mt-5 border-solid w-44 rounded-lg m-auto">
   <img  src={First} className="rounded-full" alt="first"/>
   <h6 className="bold m-2">Wax de-capping roller</h6>
   <button onClick={() => openModal("product_01")} className="bg-none border product mt-3 p-1.5 border-solid rounded-lg w-44" style={{ color: "#0891f3"}}>Buy Now</button>
   </div>
   <div className="border h-44 border-solid mt-5 w-44 rounded-lg m-auto">
   <img  src={Second} className="rounded-full" alt="second" />
   <h6 className="bold m-2">Plastic super frame</h6>
   <button onClick={() => openModal("product_02")} className="bg-none border product p-1.5 border-solid rounded-lg mt-3 w-44" style={{ color: "#0891f3"}}>Buy Now</button>
   </div>
   </div>
   <div className="flex mt-28 mb-28">
   <div className="border h-44 mt-5 border-solid w-44 rounded-lg m-auto">
   <img  src={Third} className="rounded-full" alt="third" />
   <h6 className="bold m-2">Plastic super frame</h6>
   <button onClick={() => openModal("product_03")} className="bg-none mt-3 border product p-1.5 border-solid rounded-lg w-44" style={{ color: "#0891f3"}}>Buy Now</button>
   </div>
   <div className="border h-44 border-solid mt-5 w-44 rounded-lg m-auto">
   <img  src={Fourth} className="rounded-full" alt="fourth" />
   <h6 className="bold m-2">Plastic super frame</h6>
   <button onClick={() => openModal("product_04")} className="bg-none mt-3 border product p-1.5 border-solid rounded-lg w-44" style={{ color: "#0891f3"}}>Buy Now</button>
   </div>
   </div>
   <div className="hr"></div>
   </>
  )
}