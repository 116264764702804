import React, { useState, useEffect } from 'react';
import Logo from "./images/bee.jpg";
import logo from "./style/bee.jpg";

export default function Header() {
  const [showHeader, setShowHeader] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY <= 110) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <>
     <nav className={`bg-white fixed top-0 fixed w-full z-20 top-0 border-b border-gray-200 transition-transform duration-300 product ${showHeader ? 'translate-y-0' : '-translate-y-full'}`} style={{backgroundColor: "#FBFCF8"}}>
        <div className="max-w-screen-xl flex items-center justify-between mx-auto p-4">
          <a href="https://malgudibeefarm.com/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src={logo} className="h-8" alt="malgudibeefarm logo" height="30" width="30" />
            <a className="text-lg" href="/">Malgudi <span style={{color: '#e99c2e'}}>Bee Farms</span></a>
          </a>
          <div className="flex space-x-3 items-end">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
	<path fill="currentColor" d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3zM9.5 14q1.875 0 3.188-1.312T14 9.5q0-1.875-1.312-3.187T9.5 5Q7.625 5 6.313 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14"></path>
</svg>
          </div>
        </div>
      </nav>
    <div
      style={{
        background: "linear-gradient(to bottom, rgb(0, 184, 214) 0%, rgb(3, 79, 135) 100%)"
      }}
      className="h-44 flex flex-col justify-between px-4 rounded-b-3xl"
    >
      <div className="flex justify-end m-3 rounded-full">
      <div className="rounded-full p-2" style={{ backgroundColor: "#0096AC"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
	<path fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m17 17l4 4M3 11a8 8 0 1 0 16 0a8 8 0 0 0-16 0" /></svg>
        </div>
      <a href="https://wa.me/+919341238495">
      <div className="rounded-full p-2 ml-2" style={{ backgroundColor: "#0096AC"}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
	<path fill="white" d="M19.05 4.91A9.82 9.82 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01m-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18l-3.12.82l.83-3.04l-.2-.31a8.26 8.26 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24c2.2 0 4.27.86 5.82 2.42a8.18 8.18 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23m4.52-6.16c-.25-.12-1.47-.72-1.69-.81c-.23-.08-.39-.12-.56.12c-.17.25-.64.81-.78.97c-.14.17-.29.19-.54.06c-.25-.12-1.05-.39-1.99-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.14-.25-.02-.38.11-.51c.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31c-.22.25-.86.85-.86 2.07s.89 2.4 1.01 2.56c.12.17 1.75 2.67 4.23 3.74c.59.26 1.05.41 1.41.52c.59.19 1.13.16 1.56.1c.48-.07 1.47-.6 1.67-1.18c.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28" /></svg>
        </div></a>
      </div>

      {/* Bottom section for the logo and text */}
      <div className="flex justify-between items-end">
        <div className="space-x-4">
          <div className="bg-white p-2 mb-3 rounded-lg">
            <img src={Logo} alt="Logo" className="h-10 w-10" />
          </div>
        </div>
        <div className="text-white text-lg mb-3 product">
          Malgudi Bee Farm
        </div>
      </div>
    </div>
    </>
  );
}