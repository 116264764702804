import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react'
import React, { useState } from 'react';
import { Drawer } from '@mui/material';
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import video from './style/reviews/VID-20240307-WA0012.mp4';
import video1 from './style/reviews/VID-20240307-WA0013.mp4'; 
import video2 from './style/reviews/VID-20240307-WA0014.mp4';
import video3 from './style/reviews/VID-20240307-WA0015.mp4';
import video4 from './style/reviews/VID-20240307-WA0016.mp4';
import image from "./style/reviews/IMG-20240307-WA0003.jpg";
import image1 from './style/reviews/IMG-20240307-WA0004.jpg';
import image2 from './style/reviews/IMG-20240307-WA0005.jpg';
import image3 from './style/reviews/IMG-20240307-WA0007.jpg';
import image4 from './style/reviews/IMG-20240307-WA0010.jpg';


const files = [
  { src: image, type: 'image' },
  { src: video, type: 'video' },
  { src: image1, type: 'image' },
  { src: video1, type: 'video' },
  { src: image2, type: 'image' },
  { src: video2, type: 'video' },
  { src: image3, type: 'image' },
  { src: video3, type: 'video' },
  { src: image4, type: 'image' },
  { src: video4, type: 'video' }
];

export default function About() {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
  <>
      <Drawer
        anchor="bottom"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      > 
        <div className="p-10">
          <div className="slide-container">
           <div class="max-w-md mx-auto text-center">
            <h3 class="text-2xl product text-gray-900 sm:text-3xl animate__animated animate__backInLeft">Product Review</h3>
            <p class="mt-4 text-base font-normal leading-7 w-10/12 m-auto text-gray-600 animate__animated animate__backInRight">Plastic supers frames are made of food grade plastics of high quality and virgin grade plastics.</p>
        </div>
        </div>
        </div>
        <div className="mb-2">
          <Zoom scale={5}>
        {files.map((file, index) =>
          file.type === 'image' ? (
            <img key={index} style={{ width: '60%', height: "250px", margin: "auto"}} alt="" src={file.src} />
          ) : (
            <video key={index} style={{ width: '100%', height: "250px" }} autoplay controls>
              <source src={file.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )
        )}</Zoom></div>
        <button onClick={() => setIsOpen(false)} className="bg-none border product p-1.5 border-solid rounded-lg mt-3 mb-5 m-auto w-11/12 border border-black" style={{ color: "#0891f3"}}>Close</button>
      </Drawer>
     <div className="flex items-center my-4 mt-8 relative m-5">
   <span className="absolute left-0 text-gray-600 bg-white text-sm font-bold hrfont px-2">ABOUT US</span>
   <hr className="flex-grow border-gray-600 border-t border-dashed border-black ml-4" />
   </div>
<Accordion className='m-5' defaultIndex={[0,2]} allowMultiple>
  <AccordionItem className='border-b border-gray-200 py-[17px]'>
    <h2>
      <AccordionButton className='flex justify-between'>
        <span className='text-left product text-navy-900 ' flex='1' textAlign='left'>About Bee Frame
        </span>
        <AccordionIcon className='text-left !text-navy-900 '/>
      </AccordionButton>
    </h2>
    <AccordionPanel className='text-left text-medium mt-2 !text-navy-900 paragraph' pb={4}>
    Plastic supers frames are made of food grade plastics of high quality and virgin grade plastics. These frames are very rugged, and tough by constuction and hexagonal hives are readily formed in frame, and can accommodate higher volume of honey compared to wooden frames where wax hives are formed by bees. These plastic super frames have a minimum life of 20 years, with minimum care and easy storage methods, frames can be cleaned with pressure water and sun dried for cleaning purpose, these plastic super frames are not damaged by pest like wax borers and wax moth insects, can be used to harvest with motorised honey harvester with higher speeds without any damage to frames, these plastic super frames can be directly used from packaging to supers in time of short spell of honey flow in your region for honey harvest, very less labour and equipment is required for honey harvest, honey is fully filled in the frames within a week & capped. These super frames are exclusively used for honey harvest in bee box supers. These frames are odorless, and We have tested these frames in GKVK Bangalore unit for honey filling and other aspects with very promising results.
    </AccordionPanel>
  </AccordionItem>
 
{" "}
 
<AccordionItem className="border-b border-gray-200 py-[17px]" onClick={() => setIsOpen(true)}>
  <h2>
    <AccordionButton className="flex justify-between">
      <span
        className="text-left product text-navy-900"
        flex="1"
        textAlign="left"
      >
        Reviews
      </span>
      <AccordionIcon className="text-left !text-navy-900" />
    </AccordionButton>
  </h2>
</AccordionItem>
 
  <AccordionItem className='border-b border-gray-200 py-[17px]'>
    <h2>
      <AccordionButton className='flex justify-between'>
        <span className='text-left product text-navy-900' flex='1' textAlign='left'>
          How To Reach Us
        </span>
        <AccordionIcon className='text-left !text-navy-900'/>
      </AccordionButton>
    </h2>
    <AccordionPanel className='text-left text-medium mt-2 !text-navy-900' pb={4}>
    <div className="flex items-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 21 21">
	<g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(4 2)">
		<path d="m6.5 16.54l.631-.711Q8.205 14.6 9.064 13.49l.473-.624Q12.5 8.875 12.5 6.533C12.5 3.201 9.814.5 6.5.5s-6 2.701-6 6.033q0 2.342 2.963 6.334l.473.624a55 55 0 0 0 2.564 3.05" />
		<circle cx="6.5" cy="6.5" r="2.5" />
	</g>
</svg>
   <span className="paragraph"> Malgudi Bee Farms #442, 94TH Cross Kumarswamy Layout 1st Stage Bengaluru </span> </div>
    <div className="flex items-center mt-2">
      <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 256 256">
	<path fill="currentColor" d="m221.59 160.3l-47.24-21.17a14 14 0 0 0-13.28 1.22a5 5 0 0 0-.56.42l-24.69 21a1.88 1.88 0 0 1-1.68.06c-15.87-7.66-32.31-24-40-39.65a1.91 1.91 0 0 1 0-1.68l21.07-25a6 6 0 0 0 .42-.58a14 14 0 0 0 1.12-13.27L95.73 34.49a14 14 0 0 0-14.56-8.38A54.24 54.24 0 0 0 34 80c0 78.3 63.7 142 142 142a54.25 54.25 0 0 0 53.89-47.17a14 14 0 0 0-8.3-14.53M176 210c-71.68 0-130-58.32-130-130a42.23 42.23 0 0 1 36.67-42h.23a2 2 0 0 1 1.84 1.31l21.1 47.11a2 2 0 0 1 0 1.67l-21.11 25.06a5 5 0 0 0-.43.57a14 14 0 0 0-.91 13.73c8.87 18.16 27.17 36.32 45.53 45.19a14 14 0 0 0 13.77-1c.19-.13.38-.27.56-.42l24.68-21a1.92 1.92 0 0 1 1.6-.1l47.25 21.17a2 2 0 0 1 1.21 2A42.24 42.24 0 0 1 176 210" />
</svg>
   <span className="paragraph ml-1"> 9341238495 </span> </div>
    <div className="flex items-center mt-2">
      <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 20 20">
	<path fill="currentColor" d="M19 5.5a4.5 4.5 0 1 1-9 0a4.5 4.5 0 0 1 9 0m-4-2a.5.5 0 0 0-1 0V5h-1.5a.5.5 0 0 0 0 1H14v1.5a.5.5 0 0 0 1 0V6h1.5a.5.5 0 0 0 0-1H15zm2 11v-4.1a5.5 5.5 0 0 0 1-.657V14.5a2.5 2.5 0 0 1-2.5 2.5h-11A2.5 2.5 0 0 1 2 14.5v-8A2.5 2.5 0 0 1 4.5 4h4.707a5.5 5.5 0 0 0-.185 1H4.5A1.5 1.5 0 0 0 3 6.5v.302l7 4.118l1.441-.848q.488.327 1.043.547l-2.23 1.312a.5.5 0 0 1-.426.038l-.082-.038L3 7.963V14.5A1.5 1.5 0 0 0 4.5 16h11a1.5 1.5 0 0 0 1.5-1.5" />
</svg>
   <span className="paragraph ml-1"> malgudi.beefarm@gmail.com </span> </div>
    <div className="flex items-center mt-2">
     <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24">
	<path fill="currentColor" d="M19.05 4.91A9.82 9.82 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91c0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21c5.46 0 9.91-4.45 9.91-9.91c0-2.65-1.03-5.14-2.9-7.01m-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18l-3.12.82l.83-3.04l-.2-.31a8.26 8.26 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24c2.2 0 4.27.86 5.82 2.42a8.18 8.18 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23m4.52-6.16c-.25-.12-1.47-.72-1.69-.81c-.23-.08-.39-.12-.56.12c-.17.25-.64.81-.78.97c-.14.17-.29.19-.54.06c-.25-.12-1.05-.39-1.99-1.23c-.74-.66-1.23-1.47-1.38-1.72c-.14-.25-.02-.38.11-.51c.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31c-.22.25-.86.85-.86 2.07s.89 2.4 1.01 2.56c.12.17 1.75 2.67 4.23 3.74c.59.26 1.05.41 1.41.52c.59.19 1.13.16 1.56.1c.48-.07 1.47-.6 1.67-1.18c.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28" />
</svg>
   <a href="https://wa.me/+919341238495" className="paragraph ml-1" style={{color : "#0891f3"}}> Whatsapp Us </a> </div>
    </AccordionPanel>
  </AccordionItem>
</Accordion>
  </>
  )
}